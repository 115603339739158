<template>
  <MainAppContainer>
        <main>
            <AddYourBusiness />
        </main>
  </MainAppContainer>
</template>

<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import AddYourBusiness from '@/components/business/addYourBusiness.vue'
export default {
    // beforeCreate: function () {
    //     var auth = this.$storage.getStorageSync('token')
    //     if (!auth) {
    //         this.$router.push('/')
    //     }else{
    //         if(this.$storage.getStorageSync('user').is_owner == 1){
    //             this.$router.push('/')
    //         }
    //     }
    // },
    components: {
        MainAppContainer,
        AddYourBusiness
    }
}
</script>