<template>
<div>
    <div class="container">
        <div class="business-list-heading">
            <!-- <h2>Let’s create your free Ondaq Business account</h2>
            <p>Registering your business with OnDaQ is fast and easy.</p> -->
            <h2>Get started with OnDaQ Business</h2>
            <!-- <p> Our time management solution will help organize and manage your effectively so, you can focus on doing what you do best.</p> -->
        </div>
    </div>
    <div class="sign-up-flow-div">
        <div class="flow-inner-div">
            <ul id="progressbar">
                <li :class="step == 1 ? 'active':''" class="services-div" id="personal">
                    <div class="dark white-circle d-none d-sm-flex">1</div>
                    <strong>Business Details</strong>
                </li>
                <li :class="step == 2 ? 'active':''" class="services-div border-end" id="services">
                    <div class="dark white-circle d-none d-sm-flex">2</div>
                    <strong>Business Types</strong>
                </li>
                <!-- <li :class="step == 3 ? 'active':''" class="right-end services-div px-2 px-sm-0" id="billing">
                    <div class="dark white-circle d-none d-sm-flex">3</div>
                    <strong>Working Plan</strong>
                </li> -->
            </ul>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <div class="services-form">
                    <form id="msform" @submit.prevent="addBusines" >
                        <fieldset v-if="step == 1">
                            <div class="row" v-if="!$storage.getStorageSync('token')">
                                <div class="col-lg-8 col-md-10 col-12 pb-3">
                                    <h3 class="fs-title">Owner’s account information</h3>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label for="" class="font-weight-normal">First Name</label>
                                        <input type="text" class="form-control" v-model="reg_.owner_first_name" :class="[v$.reg_.owner_first_name.$error ? 'border-danger': '']" placeholder="John" />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label for="" class="font-weight-normal">Last Name</label>
                                        <input type="text" class="form-control" v-model="reg_.owner_last_name" :class="[v$.reg_.owner_last_name.$error ? 'border-danger': '']" placeholder="Doe" />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label class="font-weight-normal">Gender</label>
                                        <select class="form-select" :class="[v$.reg_.gender.$error ? 'border-danger': '']" v-model="reg_.gender">
                                            <option value="">Choose your Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label for="" class="font-weight-normal">Email</label>
                                        <input type="text" class="form-control" v-model="reg_.owner_email" :class="[v$.reg_.owner_email.$error ? 'border-danger': '']"  @blur="validateEmail" placeholder="username@example.com" onke />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label for="" class="font-weight-normal">Create a Password</label>
                                        <div class="input-group">
                                            <input :type="passwordType" class="form-control" v-model="reg_.owner_password" :class="[v$.reg_.owner_password.$error ? 'border-danger': '']" placeholder="Password" />
                                            <span class="input-group-text"><fa :icon="['far','eye']" v-show="passwordType=='text'" @click="switchVisibility" /><fa @click="switchVisibility" v-show="passwordType=='password'" :icon="['far','eye-slash']" /></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 pb-3">
                                    <div class="form-group">
                                        <label for="" class="font-weight-normal">Confirm Password</label>
                                        <input type="password" class="form-control" v-model="reg_.owner_c_password" :class="[v$.reg_.owner_c_password.$error ? 'border-danger': '']" placeholder="Confirm Password" />
                                        <!-- <div v-if="v$.reg_.owner_c_password.$error && this.v$.reg_.owner_c_password.$errors[0].$validator == 'sameAsPassword'" class="form-error-msg-labeled">
                                        <span v-if="this.v$.reg_.owner_c_password.$errors[0].$validator == 'sameAsPassword'">Password don't match</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-10 col-12 pb-3">
                                    <h3 class="fs-title">Enter your business name</h3>
                                    <div class="form-group">
                                        <!-- <label for="" class="font-weight-normal">Business Name</label> -->
                                        <input type="text" class="form-control" v-model="reg_.business_name" :class="[v$.reg_.business_name.$error ? 'border-danger': '']" placeholder="Ondaq Beauty Salon" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <h3 class="fs-title">Business address</h3>
                                    <div class="form-group">
                                        <input type="text" class="form-control" v-model="reg_.address.complete_address" :class="[v$.reg_.address.complete_address.$error ? 'border-danger': '']" placeholder="Search address…" ref="origin" />
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="font-weight-normal">City</label>
                                                <input type="text" class="form-control" v-model="reg_.address.city" :class="[v$.reg_.address.city.$error ? 'border-danger': '']" placeholder="Charlotte" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="font-weight-normal">State</label>
                                                <input type="text" class="form-control" v-model="reg_.address.state" :class="[v$.reg_.address.state.$error ? 'border-danger': '']" placeholder="North Carolina" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="" class="font-weight-normal">Street</label>
                                                <input type="text" class="form-control" v-model="reg_.address.street" :class="[v$.reg_.address.street.$error ? 'border-danger': '']" placeholder="100 John Doe Street" name="" required="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="" class="font-weight-normal">Zipcode</label>
                                                <input type="text" class="form-control" v-model="reg_.address.zip" :class="[v$.reg_.address.zip.$error ? 'border-danger': '']" placeholder="12345"  />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="" class="font-weight-normal">Apt/Suite No</label>
                                                <input type="text" class="form-control" v-model="reg_.apt" placeholder="eg. 200" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="" class="font-weight-normal">Phone Number</label>
                                                <vue-tel-input v-model="reg_.phone" @country-changed="updateCountryCode" :class="[v$.reg_.phone.$error ? 'border-danger': '']" :onlyCountries="['US','PK']" defaultCountry="US" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
                                                <!-- <input type="text" class="form-control" value="" placeholder="(000) 000 - 0000" /> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div ref="map" id="map" class="my-3"></div>
                                </div>
                                <div class="col-lg-6 col-md-8 col-12 pt-3">
                                    <h3 class="fs-title">How did you hear about OnDaQ?</h3>
                                    <div class="form-group">
                                        <select class="form-select" v-model="reg_.reference" :class="[v$.reg_.reference.$error ? 'border-danger': '']">
                                            <option value="">Select Option</option>
                                            <option>Ondaq website</option>
                                            <option>Referral</option>
                                            <option>Google</option>
                                            <option>Facebook</option>
                                            <option>YouTube</option>
                                            <option>LinkedIn</option>
                                            <option>Instagram</option>
                                            <option>Influencer</option>
                                            <option>Radio</option>
                                            <option>TV</option>
                                            <option>A Friend</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-md-end text-center">
                                    <small>By clicking continue, it means you agree to Ondaq’s <router-link :to="{name:'TermsofService'}">Terms Of Service</router-link> and <router-link :to="{name:'PrivacyPolicy'}">Privacy Policy</router-link>.</small>
                                </div>
                            </div>
                            <input type="button" v-on:click="nextStep(2,false)" name="next" class="next action-button float-md-end m-auto d-block mt-2" value="Continue" />
                        </fieldset>

                        <fieldset v-if="step == 2">
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-4 px-1" v-for="btype in businessTypes" :key="btype.id">
                                    <div class="card cate-card" :class="selectBusinessType(btype,'check') > -1 ? 'active':''">
                                        <div class="card-body" v-on:click="selectBusinessType(btype,'add')">
                                            <img v-if="btype.icon" :src="btype.icon" class="card-img-top" :alt="btype.title" style="width:66px;height:66px">
                                            <div><h6 class="card-title">{{btype.title}}</h6></div>
                                        </div>
                                        <fa icon="check-circle" class="fa-lg" />
                                    </div>
                                </div>
                            </div>
                            <input type="button" v-on:click="nextStep(1,true)" name="previous" class="previous action-button-previous" value="Previous" />
                            <input type="submit" class="next action-button float-end" value="Continue" />
                            <!-- <input type="button" v-on:click="nextStep(3,false)" name="next" class="next action-button float-end" value="Continue" /> -->
                        </fieldset>

                        <!-- <fieldset v-if="step == 3"> -->
                             <!-- <div class="row gx-2 services-form text-center d-flex justify-content-between">
                                <h2 class="">Pricing That Fits Every Business</h2>
                                <div v-on:click="changePlan(1)" class="col-5 my-4 sub-box" :class="reg_.subscription.type === 1 ? 'active':''" >
                                    <h5 class="mt-4">Pay As you Earn</h5>
                                    <p class="para-color my-4">If you are an employee, you normally pay tax through PAYE. Every time your salary is paid, your employer deducts Income Tax (IT).</p>
                                    <div class="mt-1">
                                        <span class="h4">10%+50C</span><span class="text-muted"> per transaction</span>
                                        <p class="pt-3">* Including Tax</p>
                                    </div>
                                    <div class="select-mark" v-if="reg_.subscription.type === 1">
                                        <fa class="checkcircle " :icon="['fas', 'check-circle']" />
                                    </div>
                                </div>
                                <div v-on:click="changePlan(2)" class="col-5 my-4 sub-box" :class="reg_.subscription.type === 2 ? 'active':''">
                                    <h5 class="mt-4">Subscription</h5>
                                    <div class="text-center rounded">
                                        <div class="m-4 rounded">
                                            <p>Here is what you'll pay</p>
                                            <span class="h1">${{reg_.subscription.plan.amount}} </span><span class="text-muted"> per month</span>
                                        </div>
                                        <p>For anything worth having pay the price</p>
                                        <div class="row independent-box">
                                            <div v-for="plan in plans" :key="plan.id" class="col change-bg p-2" v-on:click="reg_.subscription.plan = plan" :class="reg_.subscription.plan.id == plan.id ? 'active':''" >{{plan.title}}</div>
                                        </div>
                                    </div>
                                    <div class="select-mark" v-if="reg_.subscription.type === 2">
                                        <fa class="checkcircle " :icon="['fas', 'check-circle']" />
                                    </div>
                                </div>
                            </div> -->

                            <!-- <h3 class="fs-title mb-4">Are you working as a team or an individual?</h3>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="plan-box" :class="reg_.business_strength == 'individual' ? 'plan-active':v$.reg_.business_strength.$error ? 'border-danger':''" v-on:click="reg_.business_strength = 'individual'">
                                        <div class="plan-icon">
                                            <img src="@/assets/images/icons/individual.png" />
                                            <span class="check-mark"><img src="@/assets/images/icons/list-icon.png" /> <i class="fa fa-check" aria-hidden="true"></i></span>
                                        </div>
                                        <h3 class="plan-title">Individual</h3>
                                        <p>You got this!</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="plan-box" :class="reg_.business_strength == 'team' ? 'plan-active':v$.reg_.business_strength.$error ? 'border-danger':''" v-on:click="reg_.business_strength = 'team'">
                                        <div class="plan-icon">
                                            <img src="@/assets/images/icons/team.png" />
                                            <span class="check-mark"><img src="@/assets/images/icons/list-icon.png" /> <i class="fa fa-check" aria-hidden="true"></i></span>
                                        </div>
                                        <h3 class="plan-title">Team</h3>
                                        <p>For 2 or more professionals</p>
                                    </div>
                                </div>
                            </div>
                            <h3 class="fs-title mb-4 mt-4">Which location would you render services?</h3>
                            <div class="row pb-4">
                                <div class="col-lg-8 col-12">
                                    <div class="business mb-3">
                                        <div class="form-check form-check-inline">
                                            <input true-value="yes" false-value="no" v-model="reg_.service_render_position.business" class="form-check-input" type="checkbox" id="inlineCheckbox1" />
                                            <label class="form-check-label fs-6 fw-normal" for="inlineCheckbox1">Business location</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input true-value="yes" false-value="no" v-model="reg_.service_render_position.client" class="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                            <label class="form-check-label fs-6 fw-normal" for="inlineCheckbox2">Client preference</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-12">
                                    <div class="form-group" v-show="reg_.service_render_position.client == 'yes'">
                                        <label for="" class="font-weight-normal">Serving Distance</label>
                                        <select class="form-select" v-model="reg_.service_render_position.distance" :class="[v$.reg_.service_render_position.distance.$error ? 'border-danger': '']">
                                            <option value="">Choose Distance</option>
                                            <option value="10">10 miles</option>
                                            <option value="25">25 miles</option>
                                            <option value="50">50 miles</option>
                                            <option value="100">100 miles</option>
                                            <option value="300">300 miles</option>
                                            <option value="500">500 miles</option>
                                        </select> -->
                                        <!-- <input :class="[v$.reg_.service_render_position.distance.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Enter distance in miles" v-model="reg_.service_render_position.distance" /> -->
                                    <!-- </div>
                                </div>
                            </div> -->
                            <!-- <input type="button" v-on:click="nextStep(2,true)" name="previous" class="previous action-button-previous" value="Previous" />
                            <input type="submit" class="next action-button float-end" value="Continue" />
                        </fieldset> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
//import 'vue-tel-input/dist/vue-tel-input.css';
import useVuelidate from '@vuelidate/core'
import { required,email,requiredIf,numeric,sameAs } from '@vuelidate/validators'
import $ from 'jquery'
import axios from 'axios'
import { useToast } from "vue-toastification";

export default {
    name: "addYourBusiness",
    components: {VueTelInput},
    setup () {
        const toast = useToast();
        return { v$: useVuelidate(),toast }
    },
    data() {
        return {
            step:1,
            // categoriesList:[],
            businessTypes:[],
            reg_:{
                owner_first_name:'',
                owner_last_name:'',
                owner_email:'',
                owner_password:'',
                owner_c_password:'',
                gender:'',
                business_name:'',
                phone:'',
                dialCode:1,
                address:{
                    complete_address:'',
                    lat:'',
                    lng:'',
                    country:'',
                    state:'',
                    city:'',
                    street:'',
                    zip:'',
                    neighborhood:''
                },
                apt:'',
                reference:'',
                businessTypes:[],
                // subscription:{
                //     type:1,
                //     plan:{}
                // },
                //business_strength:'',
                service_render_position:{
                    business:'yes',
                    client:'no',
                    distance:''
                }
            },
            // plans:[],
            passwordType:'password',
        }
    },
    validations () {
        return {
            reg_: {
                owner_first_name:{ required },
                owner_last_name:{ required },
                owner_email:{ required,email },
                owner_password:{ required },
                owner_c_password: { required, sameAsPassword:sameAs(this.reg_.owner_password) },
                gender:{ required },
                business_name:{ required },
                phone:{ required },
                address:{
                    complete_address:{ required },
                    lat:{ required },
                    lng:{ required },
                    country:{ required },
                    state:{ required },
                    city:{ required },
                    street:{ required },
                    zip:{ required }
                },
                reference:{ required },
                //business_strength:{ required },
                service_render_position:{
                    distance: {
                        requiredIfFoo: requiredIf(this.reg_.service_render_position.client == 'yes'),
                        numeric
                    }
                }
            }
        }
    },
    // created() {
    //     this.getCategories()

    // },
    directives: {mask},
    // computed:{
    //     testVuex(){
    //         return this.$store.state.msg
    //     }
    // },
    methods: {
        // changePlan(type){
        //     this.reg_.subscription.type = type
        // },
        // getPlan(){
        //     let thiss= this
        //     axios.get('subscription_plans').then(function (response) {
        //         if (response.data.status == 'success') {
        //             thiss.plans = response.data.data
        //             if (thiss.plans.length > 0) {
        //                 thiss.reg_.subscription.plan = thiss.plans[0]
        //             }
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
        // },
        switchVisibility() {
          this.passwordType = this.passwordType === "password" ? "text" : "password";
        },
        updateCountryCode(data){
            if (data.dialCode) {
                this.reg_.dialCode = data.dialCode
            }
        },
        nextStep(stepNum,isValid){
            if(isValid){
                this.step = stepNum
            }else{
                this.v$.reg_.$touch()
                if (stepNum == 2) {
                    if (!this.v$.reg_.business_name.$error
                        && !this.v$.reg_.phone.$error
                        && !this.v$.reg_.reference.$error
                        && !this.v$.reg_.address.complete_address.$error
                        && !this.v$.reg_.address.lat.$error
                        && !this.v$.reg_.address.lng.$error
                        && !this.v$.reg_.address.country.$error
                        && !this.v$.reg_.address.state.$error
                        && !this.v$.reg_.address.city.$error
                        && !this.v$.reg_.address.street.$error
                        && !this.v$.reg_.address.zip.$error
                        ) {
                            this.step = stepNum
                    }
                }else if (stepNum == 3) {
                    if (this.reg_.businessTypes.length > 0) {
                        this.step = stepNum
                    }else{
                        this.toast.error("Please choose business types.");
                    }
                }


                // if (stepNum == 3) {
                //     if (this.reg_.categories.length > 0) {
                //         this.step = stepNum
                //     }else{
                //         this.toast.error("Please choose business categories.");
                //     }
                // }else{
                //     this.v$.reg_.$touch()
                //     if (!this.v$.reg_.$error) {
                //         this.step = stepNum
                //     }else{
                //         console.log(this.v$.reg_)
                //     }
                // }
            }
        },
        // getCategories(){
        //     let thiss= this
        //     axios.get('categories').then(function (response) {
        //         if (response.data.status == 'success') {
        //             thiss.categoriesList = response.data.data
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
        // },
        getBusinessTypes(){
            let thiss= this
            axios.get('business_types').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.businessTypes = response.data.data
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        selectBusinessType(bType,action){
            if (action == 'add') {
                if($.inArray(bType, this.reg_.businessTypes) == -1){
                    this.reg_.businessTypes.push(bType)
                }else{
                    this.reg_.businessTypes.splice($.inArray(bType, this.reg_.businessTypes), 1);
                }
            }else{
                return $.inArray(bType, this.reg_.businessTypes)
            }



            // for (var z = 0; key = this.reg_.categories; z++) {
            //     if (key.includes(category)) {
            //         var value = key[1];
            //         key[1] = [ category, value++ ];
            //         console.log("category updated");
            //     } else {
            //         array.push( [ category, 1 ]);
            //         console.log("category created");
            //     }
            // }

            // this.reg_.categories.push(cate)
            // console.log(this.reg_.businessTypes)
        },
        addBusines(){
            const isFormValid = this.v$.reg_.$validate()
            if (isFormValid) {
                let thiss= this
                let url = 'listYourBusiness'
                if(!this.$storage.getStorageSync('token')){
                    url = 'list_your_business'
                }
                axios.post(url,this.reg_).then(function (response) {
                    if (response.data.status == 'success') {
                        if (response.data.data.token != '' && response.data.data.user != '') {
                            thiss.$storage.setStorageSync("user",response.data.data.user)
                            thiss.$storage.setStorageSync("token",response.data.data.token)
                        }
                        thiss.$storage.setStorageSync("role",response.data.data.role)
                        thiss.$storage.setStorageSync("business",response.data.data.business)
                        thiss.$router.push({ name: 'BusinessDashboard'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            $("#loginModal").modal('show')
                        }
                    }
                    //this.reqProcessing = false
                });
            }
        },
        getAddressArray(place){
            var comp_address = {complete_address:'',lat:'',lng:'',street:'',city:'',state:'',country:'',zip:'',neighborhood:''}
            comp_address.complete_address = place.formatted_address
            comp_address.lat = place.geometry.location.lat()
            comp_address.lng = place.geometry.location.lng()
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == "street_number") {
                    comp_address.street += place.address_components[i]['short_name']
                }else if (addressType == "route") {
                    comp_address.street += ' '+place.address_components[i]['long_name']+' '
                }else if (addressType == "locality") {
                    comp_address.city = place.address_components[i]['long_name']
                }else if (addressType == "administrative_area_level_1") {
                    comp_address.state = place.address_components[i]['short_name']
                }else if (addressType == "country") {
                    comp_address.country = place.address_components[i]['long_name']
                }else if (addressType == "postal_code") {
                    comp_address.zip = place.address_components[i]['short_name']
                }else if(addressType == "neighborhood"){
                    comp_address.neighborhood = place.address_components[i]['short_name']
                }
            }
            this.reg_.address = comp_address
            // console.log(this.reg_.address)
        },
        validateEmail(){
            // let thiss= this
            // axios.get('categories').then(function (response) {
            //     if (response.data.status == 'success') {
            //         thiss.categoriesList = response.data.data
            //     }
            // })
            // .catch(error => {
            //     console.log(error)
            // });
        }
    },
    mounted() {
        // Display Map
        const map = new window.google.maps.Map(this.$refs["map"], {
            center: { lat: 35.0875741, lng: -84.3527136 },
            zoom: 8,
            mapTypeControl: false,
            //fullscreenControl: false,
            streetViewControl: false,
            mapTypeId: 'roadmap'
        });

        // Configer Get Data From Autocomplete
        let thiss = this
        const autocomplete = new window.google.maps.places.Autocomplete(this.$refs["origin"],{componentRestrictions:{country:['US','CA','PK']}});
        var markers = [];
        autocomplete.addListener('place_changed', function () {
            const place = autocomplete.getPlace();

            // Get Complete Address Array
            thiss.getAddressArray(place)

            // Clear all Old markers
            markers.forEach(function (marker) {
                marker.setMap(null);
            });
            markers = [];

            // Draw New Marker on Map
            var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
            };
            var newMarker = new window.google.maps.Marker({
                map: map,
                icon: icon,
                draggable:true,
                title: place.name,
                position: place.geometry.location
            });
            markers.push(newMarker);

            // Set Map Bounds in Center
            var bounds = new window.google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

            // Update position after drag the marker
            var geocoder = new window.google.maps.Geocoder();
            window.google.maps.event.addListener(newMarker,'dragend', function(){
                geocoder.geocode({'latLng': newMarker.getPosition()}, function(results, status) {
                    if (status == window.google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            thiss.getAddressArray(results[0])
                        }
                    }
                })
            });
        })
        map.addListener('bounds_changed', function () {
            autocomplete.setBounds(map.getBounds());
        });
        this.getBusinessTypes();
    }
}
</script>
<style scoped>
#progressbar {
    justify-content: center;
}
#msform .action-button {
    padding: 10px 60px;
}
@media (max-width: 575px){

    #progressbar li {
        padding: 16px 0;
        width: 47%;
        font-size: 12px;
    }

}

</style>
